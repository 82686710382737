<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="3" lg="3" sm="6">
            <b-form-group label="Agency" label-for="agency" class="mb-2">
              <b-form-input
                  id="username"
                  v-model="search.username"
                  class="form-control"
                  placeholder="Please Enter Agency"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3" lg="3" sm="6">
            <b-form-group label="Code" label-for="code" class="mb-2">
              <b-form-input
                  id="code"
                  v-model="search.code"
                  class="form-control"
                  placeholder="Please Enter Code of Agency"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3" lg="3" sm="6">
            <b-form-group label="Month" label-for="month" class="mb-2">
              <v-select
                  v-model="search.month"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="monthOption"
                  :clearable="false"
                  placeholder="Select month"
                  label="Month"
                  input-id="month"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3" lg="3" sm="6">
            <b-form-group label="Year" label-for="year" class="mb-2">
              <v-select
                  v-model="search.year"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="yearOption"
                  :clearable="false"
                  placeholder="Select year"
                  label="Year"
                  input-id="year"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="d-flex align-items-end justify-content-end">
          <b-col
              cols="12"
              md="4"
              class="d-flex align-items-end justify-content-end"
          >
            <b-button variant="outline-primary" @click="resetFilter()">
              Reset
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter()">
              Search
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="p-1" v-if="$can('export', 'agencycommissions')">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" class="d-flex justify-content-end">
            <!-- md="3" -->
            <b-button
                v-if="!showLoadingExport"
                variant="primary"
                @click="exportData"
            >
              <feather-icon icon="DownloadIcon" size="16"/>
              {{ $t("Export") }}
            </b-button>
            <b-button v-else variant="primary" disabled class="mr-1">
              <b-spinner small/>
              <feather-icon icon="DownloadIcon" size="16"/>
              {{ $t("Exporting") }}
            </b-button>
            <div class="hidden">
              <vue-export-excel
                  ref="exportButton"
                  :data="dataExport"
                  :columns="columnsExport"
                  :file-name="exportFiletitle"
                  :file-type="'xlsx'"
                  :sheet-name="exportFileSheetName"
              >
                <feather-icon icon="DownloadIcon" size="16"/>
                {{ $t("Export") }}
              </vue-export-excel>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
          ref="refWinLossAgencyListTable"
          class="position-relative table-white-space mb-0 max-height-table"
          sticky-header
          head-variant="light"
          :items="commissionResult"
          responsive
          :fields="tableColumns"
          primary-key="index"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(discount)="data">
          {{ data.item.discount ? numberFormat(data.item.discount) : "" }}
        </template>
        <template #cell(total_amount_bonus)="data">
          {{ data.item.total_amount_bonus ? numberFormat(data.item.total_amount_bonus / 1000) : "" }}
        </template>
        <template #cell(total_amount_deposit)="data">
          {{ data.item.total_amount_deposit ? numberFormat(data.item.total_amount_deposit / 1000) : "" }}
        </template>
        <template #cell(total_amount_withdrawal)="data">
          {{ data.item.total_amount_withdrawal ? numberFormat(data.item.total_amount_withdrawal / 1000) : "" }}
        </template>
        <template #cell(total_bet)="data">
          {{ data.item.total_bet ? numberFormat(data.item.total_bet / 1000) : "" }}
        </template>
        <template #cell(total_cancel)="data">
          {{ data.item.total_cancel ? numberFormat(data.item.total_cancel / 1000) : "" }}
        </template>
        <template #cell(total_tie)="data">
          {{ data.item.total_tie ? numberFormat(data.item.total_tie / 1000) : "" }}
        </template>
        <template #cell(total_win)="data">
          {{ data.item.total_win ? numberFormat(data.item.total_win / 1000) : "" }}
        </template>
        <template #cell(bonus_first_transfer)="data">
          {{ data.item.bonus_first_transfer ? numberFormat(data.item.bonus_first_transfer / 1000) : "" }}
        </template>
        <template #cell(debt_previous_month)="data">
          {{ data.item.debt_previous_month ? numberFormat(data.item.debt_previous_month / 1000) : "" }}
        </template>
        <template #cell(total_win_loss)="data">
          <span v-if="data.item.total_win_loss > 0" class="text-primary">
            {{ numberFormat(data.item.total_win_loss) }}
          </span>
          <span v-if="data.item.total_win_loss <= 0" class="text-danger">
            {{ numberFormat(data.item.total_win_loss) }}
          </span>
        </template>
        <template #cell(fee)="data">
          {{ data.item.fee ? numberFormat(data.item.fee) : "" }}
        </template>
        <template #cell(net_amount_commision)="data">
          <span v-if="data.item.net_amount_commision > 0" class="text-primary">
            {{ numberFormat(data.item.net_amount_commision) }}
          </span>
          <span v-if="data.item.net_amount_commision <= 0" class="text-danger">
            {{ numberFormat(data.item.net_amount_commision) }}
          </span>
        </template>
        <!-- <template #cell(upd)="data">
          {{ data.item.upd ? data.item.upd : 0 }}
        </template>
        <template #cell(new_uap)="data">
          {{ data.item.new_uap ? data.item.new_uap : 0 }}
        </template>
        <template #cell(new_user_register)="data">
          {{ data.item.new_user_register ? data.item.new_user_register : 0 }}
        </template> -->
        <template #cell(note)="data">
          {{ data.item.note ? data.item.note : "" }}
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalData"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BImg, BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {ref, watch, computed, onUnmounted} from "@vue/composition-api";
import {formatDateTime, numberFormat} from "@core/utils/filter";
import {useToast} from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import marketingStoreModule from "@/views/marketing/marketingStoreModule";
import AddSportsTeamModal from "@/views/sports/AddSportsTeam.vue";
import UpdateSportTeamModal from "@/views/sports/UpdateSportsTeam.vue";
import flatPickr from "vue-flatpickr-component";
import i18n from "@/libs/i18n";
import VueExportExcel from "@/views/extensions/export/VueExportExcel";

export default {
  components: {
    flatPickr,
    AddSportsTeamModal,
    UpdateSportTeamModal,
    VueExportExcel,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
    BSpinner,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
    },
  },
  data() {
    return {
      sportTeamDetail: null,
      exportFiletitle: `AgencyCommission-${new Date()
          .toJSON()
          .replace(":", "-")
          .substring(0, 16)}`,
      exportFileSheetName: "AgencyCommission",
    };
  },
  methods: {},
  setup(props) {
    if (!store.hasModule("marketing"))
      store.registerModule("marketing", marketingStoreModule);
    onUnmounted(() => {
      if (store.hasModule("marketing")) store.unregisterModule("marketing");
    });

    const getListYear = () => {
      const currentYear = new Date().getFullYear();
      const startYear = 2023;
      const yearList = [];
      for (let i = startYear; i <= currentYear; i++) {
        yearList.push(i);
      }
      return yearList;
    };

    const showLoadingExport = ref(false);
    const dataExport = ref([]);
    const exportButton = ref(null);
    const search = ref({
      username: "",
      code: "",
      month: "",
      year: "",
    });
    // Status: 1:place bet, 2: win 3: loss, 4:cancel, 5: tie (lưu í nếu status nào k có thì hiển thị trống)
    const monthOption = ref(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']);
    const yearOption = ref(getListYear());
    const sportTypeIdFilter = ref(null);
    const toDateFilter = ref(null);
    const fromDateFilter = ref(null);
    const perPage = ref(25);
    const totalData = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const isProductId = ref(false);

    const refWinLossAgencyListTable = ref(null);
    const isMonth = ref(false);
    const isYear = ref(false);
    const tableColumns = [
      {key: "agencyName", label: i18n.t("Agency name"), sortable: false},
      {key: "agencyCode", label: i18n.t("Agency code"), sortable: false},
      {key: "year", label: i18n.t("Year"), sortable: true},
      {key: "month", label: i18n.t("Month"), sortable: true},
      {key: "discount", label: i18n.t("Hoa hồng (%)"), sortable: true},
      {key: "total_amount_deposit", label: i18n.t("Total amount deposit"), sortable: true},
      {key: "total_amount_withdrawal", label: i18n.t("Total amount withdrawal"), sortable: true},
      {key: "total_bet", label: i18n.t("Total bet"), sortable: true},
      {key: "total_cancel", label: i18n.t("Total cancel"), sortable: true},
      {key: "total_quantity_deposit", label: i18n.t("Total quantity deposit"), sortable: true},
      {key: "total_quantity_withdrawal", label: i18n.t("Total quantity withdrawal"), sortable: true},
      {key: "total_tie", label: i18n.t("Total tie"), sortable: true},
      {key: "total_win", label: i18n.t("Total win"), sortable: true},
      {key: "total_win_loss", label: i18n.t("Total win loss"), sortable: true},
      {key: "total_amount_bonus", label: i18n.t("Total amount bonus"), sortable: true},
      {key: "fee", label: i18n.t("Fee"), sortable: true},
      {key: "net_amount_commision", label: i18n.t("net Amount Commision"), sortable: true},
      {key: "bonus_first_transfer", label: i18n.t("Bonus First Transfer"), sortable: true},
      {key: "debt_previous_month", label: i18n.t("Debt Previous Month"), sortable: true},
      {key: "upd", label: i18n.t("UPD"), sortable: false},
      {key: "upb", label: i18n.t("UPB"), sortable: false},
      {key: "new_uap", label: i18n.t("UAP New"), sortable: false},
      {key: "new_user_register", label: i18n.t("New User"), sortable: false},
      {key: "note", label: i18n.t("note"), sortable: false}
    ];

    const columnsExport = [
      {field: "agencyName", label: i18n.t("Agency name")},
      {field: "agencyCode", label: i18n.t("Agency code")},
      {field: "year", label: i18n.t("Year")},
      {field: "month", label: i18n.t("Month")},
      {field: "discount", label: i18n.t("Hoa hồng (%)"), dataFormat: (value) => value ? numberFormat(value) : ""},
      {
        field: "total_amount_bonus",
        label: i18n.t("Total amount bonus"),
        dataFormat: (value) => value ? numberFormat(value/1000) : ""
      },
      {
        field: "total_amount_deposit",
        label: i18n.t("Total amount deposit"),
        dataFormat: (value) => value ? numberFormat(value/1000) : ""
      },
      {
        field: "total_amount_withdrawal",
        label: i18n.t("Total amount withdrawal"),
        dataFormat: (value) => value ? numberFormat(value/1000) : ""
      },
      {
        field: "total_bet",
        label: i18n.t("Total bet"),
        dataFormat: (value) => value ? numberFormat(value/1000) : ""
      },
      {
        field: "total_cancel",
        label: i18n.t("Total cancel"),
        dataFormat: (value) => value ? numberFormat(value/1000) : ""
      },
      {
        field: "total_quantity_deposit",
        label: i18n.t("Total quantity deposit")
      },
      {
        field: "total_quantity_withdrawal",
        label: i18n.t("Total quantity withdrawal")
      },
      {
        field: "total_tie",
        label: i18n.t("Total tie"),
        dataFormat: (value) => value ? numberFormat(value/1000) : ""
      },
      {
        field: "total_win",
        label: i18n.t("Total win"),
        dataFormat: (value) => value ? numberFormat(value/1000) : ""
      },
      {
        field: "total_win_loss",
        label: i18n.t("Total win loss"),
        dataFormat: (value) => value ? numberFormat(value) : ""
      },
      {field: "fee", label: i18n.t("Fee"), dataFormat: (value) => value ? numberFormat(value) : ""},
      {
        field: "net_amount_commision",
        label: i18n.t("Net Amount Commision"),
        dataFormat: (value) => value ? numberFormat(value) : ""
      }
    ];

    const refetchData = () => {
      refWinLossAgencyListTable.value.refresh();
    };

    watch(
        [currentPage],
        () => {
          sportTeamLists();
        }
    );
    const commissionResult = ref([]);
    const sportTeamLists = (ctx, callback) => {
      store
          .dispatch("marketing/fetchAgencyCommission", {
            agencyName: search.value.username,
            agencyCode: search.value.code,
            month: search.value.month,
            year: search.value.year,
            toDate: toDateFilter.value,
            page: currentPage.value,
          })
          .then((response) => {
            // callback(response.sportTeam);
            commissionResult.value = response.result.map(item => {
              return {
                ...item,
                agencyName: item.agency?.username,
                agencyCode: item.agency?.code,
                fee: item.fee / 1000,
                net_amount_commision: item.net_amount_commision / 1000,
                total_win_loss: item.total_win_loss / 1000
              }
            });
            totalData.value = response.total;
          });
    };

    store
        .dispatch("marketing/fetchAgencyCommission", {
          page: currentPage.value,
        })
        .then((response) => {
          // callback(response.sportTeam);
          commissionResult.value = response.result.map(item => {
            return {
              ...item,
              agencyName: item.agency?.username,
              agencyCode: item.agency?.code,
              fee: item.fee / 1000,
              net_amount_commision: item.net_amount_commision / 1000,
              total_win_loss: item.total_win_loss / 1000
            }
          });
          totalData.value = response.total
        });

    const dataMeta = computed(() => {
      const localItemsCount = refWinLossAgencyListTable.value
          ? refWinLossAgencyListTable.value.localItems.length
          : 0;
      return {
        from:
            perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalData.value,
      };
    });

    const resetFilter = () => {
      // search.value.name = "";
      search.value.username = "";
      search.value.code = "";
      search.value.month = "";
      search.value.year = "";
      sportTeamLists();
    };

    const searchFilter = () => {
      sportTeamLists();
    };

    const exportData = async () => {
      showLoadingExport.value = true;
      await getExportData();
      exportButton.value.$el.click();
    }

    const getExportData = async () => {
      await store
          .dispatch("marketing/exportAgencyCommission", {
            agencyName: search.value.username,
            agencyCode: search.value.code,
            month: search.value.month,
            year: search.value.year,
            toDate: toDateFilter.value,
          })
          .then((response) => {
            if (response.data.code === "00") {
              dataExport.value = response.data.data.map(item => {
                return {
                  ...item,
                  agencyName: item.agency?.username,
                  agencyCode: item.agency?.code,
                  fee: item.fee / 1000,
                  net_amount_commision: item.net_amount_commision / 1000,
                  total_win_loss: item.total_win_loss / 1000
                }
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
            showLoadingExport.value = false
          });
    }

    return {
      isProductId,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refWinLossAgencyListTable,
      sportTeamLists,
      refetchData,
      toDateFilter,
      search,
      resetFilter,
      sportTypeIdFilter,
      numberFormat,
      formatDateTime,
      searchFilter,
      baseUrl,
      monthOption,
      yearOption,
      fromDateFilter,
      isMonth,
      isYear,
      getListYear,
      commissionResult,
      showLoadingExport,
      columnsExport,
      exportButton,
      dataExport,
      exportData
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
